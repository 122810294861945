import 'scss/pages/services.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import {Link} from 'gatsby';

const JoinUs = () => {
  return (
      <Layout contactForm={false} className="service-page">
        <Seo title="Join Us"/>
        <section className="hero-wrapper">
          <div className="hero">
            <div className="hero__content">
              <h1 className="hero__title">
                <Trans>Join Our Teams at YanchWare</Trans>
              </h1>
              <p className="hero__text">
                <Trans>
                  At YanchWare, you'll thrive in an environment that's buzzing with the excitement of building our own
                  innovative products and directly enhancing our customers' experiences. Join us to work with
                  bleeding-edge technology and contribute to global improvement by aligning our goals with crucial
                  Sustainable Development Goals.
                </Trans>
              </p>
              <p className="hero__text">
                <Trans>
                  Your work here isn't just about technology—it's about making a tangible impact on the world.
                </Trans>
              </p>
            </div>
            <Image name="join-us-page/team" className="hero__image"/>
          </div>
        </section>

        <section className="container">
          <h2 className="color-primary">
            <Trans>Join us!</Trans>
          </h2>
          <p>
            <Trans>
              Are you ready to be at the forefront of cloud computing innovation? At YanchWare, we're not just
              developing cutting-edge technology—we're crafting a culture of openness, growth, and collaboration that
              spans the globe. Inspired by the rich heritage of Nordic business practices, our workplace embodies
              principles of flat hierarchies, continuous improvement, and blameless problem-solving.
            </Trans>
          </p>
          <p>
            <Trans>
              We believe that our team is our greatest asset. That’s why we invest in each member, offering
              opportunities for personal and professional development that are as boundless as the cloud technologies
              we harness. Whether you're passionate about cybersecurity, cloud architecture, or creating scalable
              solutions that bridge the divides across continents, there’s a place for you here.
            </Trans>
          </p>
          <p>
            <Trans>
              Embark on a journey with YanchWare, where innovation meets inclusivity, and your career potential can
              soar as high as your aspirations.
            </Trans>
          </p>
          <h2 className="color-primary">
            <Trans>Explore Career Opportunities at YanchWare</Trans>
          </h2>
          <p>
            <Trans>
              At YanchWare, we are always on the lookout for exceptional talent to join our dynamic team. If you have
              a passion for innovation and a drive to make a difference with cutting-edge technology, we would love
              to hear from you. Even if you don't see a position that perfectly matches your skills, we encourage you
              to send us your application. We are always open to exploring potential opportunities with those who share
              our vision and ambition.
            </Trans>
          </p>
        </section>
        <section className="bob">
          <div className="boxes-container">
            <Link to="/careers/ux-designer">
              <div className="box">
                <span className="box__number">
                  <Trans>UXD</Trans>
                </span>
                <h2 className="box__title">
                  <Trans>UX Designer</Trans>
                </h2>
                <div className="box__text">
                  <Trans>
                    As a UX Designer at YanchWare, you will play a crucial role in shaping our brand identity and
                    user experience for our flagship software products
                  </Trans>
                </div>
              </div>
            </Link>

            <Link to="/careers/senior-frontend-developer">
              <div className="box">
              <span className="box__number">
                <Trans>SFD</Trans>
              </span>
                <h2 className="box__title">
                  <Trans>Senior Frontend Developer</Trans>
                </h2>
                <div className="box__text">
                  <Trans>
                    Join our Avanguardia team as a Senior Frontend Developer and play a pivotal role in
                    shaping the user experience of our flagship products.
                  </Trans>
                </div>
              </div>
            </Link>

            <Link to="/careers/technical-sales-specialist">
              <div className="box">
                <span className="box__number">
                  <Trans>TSS</Trans>
                </span>
                <h2 className="box__title">
                  <Trans>Technical Sales Specialist</Trans>
                </h2>
                <div className="box__text">
                  <Trans>
                    As a Technical Sales Specialist at YanchWare, you are the driving force behind our business growth,
                    specializing in selling our cutting-edge SaaS products to large enterprises.
                  </Trans>
                </div>
              </div>
            </Link>

            <Link to="/careers/senior-backend-developer">
              <div className="box">
                <span className="box__number">
                  <Trans>SBD</Trans>
                </span>
                <h2 className="box__title">
                  <Trans>Senior Backend Developer</Trans>
                </h2>
                <div className="box__text">
                  <Trans>
                    Join our Avanguardia team as a Senior Backend Developer and spearhead the advancement of our
                    cutting-edge cloud products
                  </Trans>
                </div>
              </div>
            </Link>

            <Link to="/careers/senior-cloud-engineer">
            <div className="box">
              <span className="box__number">
                <Trans>SCE</Trans>
              </span>
              <h2 className="box__title">
                <Trans>Senior Cloud Engineer</Trans>
              </h2>
              <div className="box__text">
                <Trans>
                  As a Senior Cloud Engineer at YanchWare, you will be instrumental in the development and optimization
                  of our cloud-based offerings.
                </Trans>
              </div>
            </div>
            </Link>
          </div>
        </section>
      </Layout>
  );
};

export default JoinUs;
